// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';
@import 'addons/font-awesome/font-awesome';
//@import 'addons/owl-carousel2/owl.carousel'
@import 'addons/layerslider/layerslider';
@import 'addons/magnific-popup/magnific-popup';
//@import 'addons/cube-portfolio/cubeportfolio'

// IMPORT TEMPLATE
@import 'functions';

/*****************************************************/

// FONTS
$font-1: "Comfortaa";
$font-2: "Oswald";
$font-3: "Open Sans";

$main-color: #00c90d;

// XXS Breakpoint
$xxs-min: 0px;
$xxs-max: 479px;

// XS Breakpoint
$xs-min: 480px;
$xs-max: 767px;

// SM Breakpoint
$sm-min: 768px;
$sm-max: 991px;

// MD Breakpoint
$md-min: 992px;
$md-max: 1199px;

// LG Breakpoint
$lg-min: 1200px;

//@media screen and (max-width: $xxs-max)
//@media screen and (max-width: $xs-max)
//@media screen and (max-width: $sm-max)
//@media screen and (max-width: $md-max)
//@media screen and (min-width: $lg-min)

/*****************************************************/

/* GENERAL TEMPLATE */
body {
	color: #000000;
	font: {
		size: 10px;
		weight: 400;
		family: $font-1; }
	line-height: 100%;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none; } }
	a[href^=tel], a[href^=mailto] {

		&:hover {} }

	p {}

	h1, h2, h3, h4, h5, h6 {}

	ul, ol {

		li {} }


	h1.referencement {
		display: none; }

	.appear {
		opacity: 0;
		@include transition(opacity 0.75s ease-in); }

	.parallax,
	.parallax-slow,
	.parallax-fast {
		background-attachment: fixed;
		@media screen and (max-width: $sm-max) {
			background-attachment: scroll; } } }

/*****************************************************/

[data-browser="safari"] .layerslider {
	height: 300px;
	@media screen and (min-width: 600px) {
		height: 400px; }
	@media screen and (min-width: 1200px) {
		height: 600px; }
	@media screen and (min-width: 1500px) {
		height: 800px; } }

/*****************************************************/

.mfp-bg {
	opacity: 0.975;
	background: #ffffff; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
	color: #000000; }

.mfp-figure:after {
	box-shadow: none; }

//CLASSES COMMUNES

/* Boutons */
.btn-lien {
	background-color: transparent;
	border: 2px solid #fff;
	color: #fff;
	display: block;
	font-size: 18px;
	font-weight: 300;
	margin: 25px auto;
	padding: 20px 0;
	width: 260px;
	@include transition(all 0.3s ease-out);
	&:hover {
		background-color: #fff;
		color: #0c1a1c; } }

/* Couleurs */
.red {
	color: #d70000; }

/* Images */
.img-center {
	margin: 0 auto; }

.img-map {
	margin-top: 50px; }

.photo-box {
	margin-bottom: 20px;
	img {
		@include transition(opacity 0.2s ease-out); }
	&:hover {
		img {
			opacity: 0.75; } } }

/* Padding */
.no-padding {
	padding: 0; }

/* Sticky scroll */
.sticky-bottom {
	background: {
		color: transparent;
		image: url(../images/ss-scrolldown-icon-35x50px.png);
		position: 0 center;
		repeat: no-repeat; }
	left: 50%;
	height: 50px;
	width: 35px;
	bottom: 25px;
	position: absolute;
	z-index: 10;
	@include transform(translateX(-50%));
	&:hover {
		background-position: -35px center; } }

/*****************************************************/



@-webkit-keyframes seconds {
	0% {
		opacity: 1; }

	100% {
		opacity: 0;
		bottom: -125%; } }


@keyframes seconds {
	0% {
		opacity: 1; }

	100% {
		opacity: 0;
		bottom: -125%; } }



/*****************************************************/

//ARTICLES
article {
	position: relative; }

.index {
	.top-index {
		background: {
			attachment: fixed;
			color: #fff;
			image: url(../images/section01-bg.jpg);
			position: center;
			repeat: no-repeat;
			size: cover; }
		padding: 110px 0;
		.circle-box {
			background-color: $main-color;
			border-radius: 50%;
			display: block;
			margin: 0 auto;
			width: 100%;
			height: 370px;
			max-width: 370px;
			position: relative;
			mix-blend-mode: multiply;
			h3 {
				color: #0c1a1c;
				font-family: $font-2;
				font-size: 72px;
				font-weight: 700;
				line-height: 72px;
				text-align: center;
				.petit {
					font-family: $font-1;
					font-size: 30px;
					font-weight: 700; }
				.strike {
					position: relative;
					top: -20px;
					&:before {
						bottom: 0;
						border-top: 2px solid #0c1a1c;
						content: "";
						left: -35px;
						position: absolute;
						top: 50%;
						width: 25px; }
					&:after {
						bottom: 0;
						border-top: 2px solid #0c1a1c;
						content: "";
						position: absolute;
						right: -35px;
						top: 50%;
						width: 25px; } } } }
		.text-box {
			padding-top: 55px;
			p {
				color: #0c1a1c;
				font-family: $font-3;
				font-size: 20px;
				font-weight: 300;
				line-height: 36px;
				padding: 25px 0;
				.gras {
					font-weight: 700; } } } }
	@media screen and (max-width: $sm-max) {
		.top-index {
			background-image: none;
			padding: 40px 0;
			.circle-box {
				background-color: $main-color;
				max-width: 275px;
				height: 275px;
				h3 {
					font-size: 54px;
					line-height: 54px;
					.petit {
						font-size: 23px; } } }
			.text-box {
				padding-top: 15px;
				p {
					font-size: 16px;
					line-height: 24px;
					padding: 15px 0; } } } } }

.contenu {
	padding: 70px 0;
	.contact-box {
		padding: 80px 0;
		a {
			color: #0c1a1c;
			&.mail {
				color: #0c1a1c;
				display: block;
				font-family: $font-1;
				font-size: 18px;
				font-weight: bold;
				line-height: 24px;
				padding-bottom: 25px; } }
		h4 {
			font-family: $font-1;
			font-size: 24px;
			font-weight: 700;
			margin: 0;
			line-height: 30px;
			padding-bottom: 25px;
			&.titre {
				padding-bottom: 0; } }
		p {
			font-family: $font-1;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			padding-bottom: 25px; }
		img {
			margin-bottom: 65px; }
		&.habitat {
			background-color: #e5e5e5;
			h4, p {
				color: #0c1a1c;
				.phone {
					color: #0c1a1c; } } }
		&.cdd {
			background-color: #f3f3f3;
			h4 {
				color: #0c1a1c;
				padding-bottom: 35px;
				.phone {
					color: #0c1a1c; } } } }
	.content-box {
		padding-top: 115px;
		h3 {
			color: #0c1a1c;
			font-family: $font-1;
			font-size: 30px;
			margin: 0;
			padding-top: 15px; }
		p {
			color: #0c1a1c;
			font-family: $font-1;
			font-size: 18px;
			line-height: 30px; }
		img {
			margin-bottom: 10px; }
		.img-box {
			border: 1px solid #989898;
			height: 320px;
			position: relative;
			margin-bottom: 10px;
			.fit {
				height: 280px;
				width: auto;
				object-fit: contain;
				left: 0;
				position: absolute;
				right: 0;
				text-align: center;
				top: 50%;
				@include transform(translateY(-50%)); } } }
	.liste-box {
		padding-left: 40px;
		.liste {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				color: #0c1a1c;
				font-family: $font-1;
				font-size: 18px;
				font-weight: 700;
				line-height: 30px;
				padding-bottom: 24px;
				padding-left: 1.4em;
				text-indent: -1.2em;
				&:before {
					color: $main-color;
					content: "• ";
					font-size: 24px;
					padding-right: 5px;
					position: relative;
					top: 3px; } }
			&.inclus {
				padding-bottom: 20px;
				h3 {
					font-weight: 700;
					line-height: 100%;
					padding-bottom: 10px;
					padding-top: 0;
					text-transform: uppercase; }
				li {
					padding-bottom: 0; } } } }
	.title-box {
		background-color: $main-color;
		p, h3 {
			color: #0c1a1c;
			font-family: $font-1;
			line-height: 36px; }
		p {
			font-size: 18px;
			padding: 36px 10px; }
		h3 {
			font-size: 36px;
			font-weight: 700;
			margin: 0;
			padding: 40px 0; } }
	@media screen and (max-width: $md-max) {
		.content-box {
			.img-box {
				height: 260px;
				.fit {
					height: 240px;
					width: auto; } } } }
	@media screen and (max-width: $sm-max) {
		padding: 10px 0;
		&.contact {
			padding: 10px; }
		.contact-box {
			padding: 35px 0;
			a {
				&.mail {
					font-size: 14px;
					font-weight: bold;
					line-height: 24px;
					padding-bottom: 20px; } }
			h4 {
				font-size: 20px;
				line-height: 24px;
				padding-bottom: 20px;
				&.titre {
					padding-bottom: 0; } }
			p {
				font-family: $font-1;
				font-size: 14px;
				padding-bottom: 20px; }
			img {
				margin-bottom: 35px; } }
		.content-box {
			padding-top: 10px;
			h3, p {
				padding: 0 10px; }
			p {
				font-size: 16px; } }
		.title-box {
			h3 {
				padding: 40px 8px;
				font-size: 30px; }
			p {
				font-size: 16px;
				line-height: 24px; } } }
	@media screen and (max-width: $xs-max) {
		.content-box {
			.img-box {
				height: 132px;
				.fit {
					height: 120px;
					width: auto; } } }
		.liste-box {
			padding-left: 10px;
			.liste {
				li {
					font-size: 16px;
					line-height: 24px;
					text-indent: -1em;
					&:before {
						padding-right: 0; } }
				&.inclus {
					h3 {
						font-size: 24px; } } } }
		.title-box {
			h3 {
				text-align: left; } } } }


/*****************************************************/

// MODULES

/* MENU */
.module-menu {
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	position: fixed;
	background: transparent;
	background-color: #000;
	.logo-container {
		display: block;
		.menu-list {
			.menu-ul {
				.menu-li {
					padding: 15px 0; } } } }
	.logo-container {
		display: block;
		float: left;
		img {
			width: auto;
			height: 62px;
			@include transition(all 0.2s ease-out); } }
	[retour] {
		display: none !important; }
	.menu-toggle {
		display: none; }
	.main-menu {
		float: right;
		position: relative;
		@include transition(all 0.2s ease-out);
		.menu-list {
			top: 0;
			right: 38px;
			position: relative;
			.menu-ul {
				margin: 0;
				padding: 0;
				.menu-li {
					float: left;
					height: 100%;
					list-style: none;
					padding: 16px 0;
					@include transition(all 0.2s ease-in);
					a {
						color: #fff;
						font-size: 20px;
						font-weight: 300;
						display: block;
						padding: 10px 15px;
						@include transition(color 0.2s ease-in);
						&.phone {
							background-color: rgba(128,128,128,0.2);
							border-radius: 4px;
							color: $main-color;
							font-size: 16px; } }
					.submenu {
						margin: 0;
						padding: 0;
						position: absolute;
						transform-origin: top center;
						@include transform(scaleY(0));
						@include transition(all 0.2s ease-out);
						.sub-li {
							list-style: none;
							a {
								border-top: 1px solid #000;
								color: #fff;
								padding: 25px;
								display: block;
								background: #262626;
								text-transform: uppercase;
								&:hover {
									color: #ff0000; } } } }
					&:hover {
						@media screen and (min-width: 1350px) {
							.submenu {
								z-index: 10;
								@include transform(scaleY(1)); } } }
					&:hover {
						@media screen and (min-width: 1350px) {
							a {
								color: $main-color; } } }
					&.active {
						@media screen and (min-width: 1350px) {
							a {
								position: relative;
								&:before {
									bottom: 0;
									border-bottom: 3px solid $main-color;
									content: "";
									left: 0;
									margin: 0 auto;
									position: absolute;
									right: 0;
									width: 90%; } } } } } } } }
	@media screen and (max-width: 1349px) {
		height: 60px;
		background: #000000;
		.logo-container {
			padding: 5px;
			img {
				height: 50px; } }
		[retour] {
			opacity: 0.5;
			display: block !important; }
		.menu-toggle {
			display: block; }
		.main-menu {
			top: 60px;
			left: 0;
			right: 0;
			bottom: 0;
			position: fixed;
			background: #d8d8d8;
			@include transform(translateY(150%));
			.menu-list {
				top: 50%;
				left: 50%;
				display: table;
				position: absolute;
				width: 90%;
				@include transform(translate(-50%, -50%));
				.menu-ul {
					.menu-li {
						float: none;
						display: block;
						padding: 5px 0;
						text-align: center;
						a {
							color: #000;
							line-height: 100% !important;
							padding: 5px; }
						.submenu {
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							position: fixed;
							background: #000;
							&.activated {
								z-index: 10;
								@include transform(scaleY(1)); } } } } }
			&.toggled {
				@include transform(translateY(0%)); } }
		.menu-toggle {
			float: right;
			cursor: pointer;
			padding: 20px; } } }


/* Slideshow */
.module-slideshow {
	overflow: hidden;
	position: relative;
	.logo {
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 50%;
		z-index: 100;
		@include transform(translateY(-50%)); }
	.title {
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		top: 54%;
		z-index: 10;
		display: block;
		@include transform(translateY(-46%));
		h2 {
			color: #fff;
			font-family: $font-2;
			font-size: 72px;
			font-weight: 700;
			margin: 0;
			text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5); } }
	@media screen and (max-width: $sm-max) {
		.logo {
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			@include transform(translateY(-50%));
			img {
				height: auto;
				max-width: 260px; } }
		.title {
			h2 {
				font-size: 30px;
				padding: 0 18px; } } } }


/* Navigation Slideshow */
.ls-noskin .ls-nav-sides, .ls-noskin .ls-bottom-nav-wrapper, .ls-noskin .ls-bottom-slidebuttons a, .ls-noskin .ls-nav-prev, .ls-noskin .ls-nav-next, .ls-noskin .ls-nav-start, .ls-noskin .ls-nav-stop {
	display: block !important;
	visibility: visible !important; }

html * .ls-nav-prev, html * .ls-nav-next, html * .ls-container img, html * .ls-bottom-nav-wrapper a, html * .ls-container .ls-fullscreen, body * .ls-nav-prev, body * .ls-nav-next, body * .ls-container img, body * .ls-bottom-nav-wrapper a, body * .ls-container .ls-fullscreen, #ls-global * .ls-nav-prev, #ls-global * .ls-nav-next, #ls-global * .ls-container img, #ls-global * .ls-bottom-nav-wrapper a, #ls-global * .ls-container .ls-fullscreen, html * .ls-thumbnail a, body * .ls-thumbnail a, #ls-global * .ls-thumbnail a {
	border: 0 none;
	line-height: normal;
	outline: medium none;
	padding: 0;
	transition: none 0s ease 0s; }

.ls-nav-prev,
.ls-nav-next {
	background: {
		image: url(../images/ss-nav-20x38px.png);
		repeat: no-repeat; }
	height: 38px;
	position: absolute;
	top: 50%;
	width: 20px;
	z-index: 99;
	@media screen and (max-width: $sm-max) {
		display: none !important; } }

.ls-nav-prev {
	background-position: 0 center;
	left: 20px;
	margin: auto;
	&:hover {
		background-position: -20px center; } }

.ls-nav-next {
	background-position: -40px center;
	right: 20px;
	margin: auto;
	&:hover {
		background-position: -60px center; } }


/* Punch Index */
.punch-index {
	background: {
		attachment: fixed;
		image: url(../images/section-punch-bg.jpg);
		position: center;
		repeat: no-repeat;
		size: cover; }
	padding: 140px 0 110px;
	h3 {
		color: #fff;
		font: {
			family: $font-2;
			size: 90px;
			weight: 700; }
		line-height: 72px;
		margin: 0;
		text-transform: uppercase;
		.small-green {
			color: $main-color;
			font-family: $font-1;
			font-size: 72px;
			font-weight: 300;
			text-transform: none; } }
	@media screen and (max-width: $sm-max) {
		background-image: url(../images/section-punch-bg-mobile.jpg);
		padding: 50px 0;
		h3 {
			font-size: 60px;
			line-height: 60px;
			.small-green {
				font-size: 48px;
				line-height: 48px; } } } }

/* Sections chooser */
.module-sections {
	background: {
		attachment: fixed;
		image: url(../images/section02-bg.jpg);
		position: center;
		repeat: no-repeat;
		size: cover; }
	padding: 110px 0;
	.item-box {
		border-radius: 10px;
		cursor: pointer;
		height: 320px;
		position: relative;
		width: 100%;
		-webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.3);
		-moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.3);
		box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.3);
		h3 {
			bottom: 15%;
			font-size: 48px;
			font-weight: 700;
			left: 0;
			margin: 0;
			position: absolute;
			right: 0;
			text-align: center; }
		&:hover {
			&.plans, &.photos, &.inclus2 {
				background-color: #0c1a1c;
				background-image: none;
				h3 {
					color: #fff; } }
			&.inclus, &.projet {
				background-color: $main-color;
				background-image: none; }
			.icon-plans {
				background-image: url(../images/icone-plan-hover.png); }
			.icon-photos {
				background-image: url(../images/icone-photos-hover.png); }
			.icon-inclus-yellow {
				background-image: url(../images/icone-inclus-yellow-hover.png); } }
		&.plans, &.inclus2 {
			background: {
				image: url(../images/item-plans-bg.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; }
			h3 {
				color: $main-color; } }
		&.inclus {
			background: {
				image: url(../images/mod-inclus-bg.png);
				position: center;
				repeat: no-repeat;
				size: cover; }
			h3 {
				color: #0c1a1c; } }
		&.photos {
			background: {
				image: url(../images/item-photos-bg.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; }
			h3 {
				color: $main-color; } }
		&.projet {
			background: {
				image: url(../images/item-projet-bg.jpg);
				position: center;
				repeat: no-repeat;
				size: cover; }
			h3 {
				color: #0c1a1c; } }
		.icon-plans, .icon-inclus, .icon-photos, .icon-projet, .icon-inclus-yellow {
			background: {
				position: center;
				repeat: no-repeat;
				size: 100%; }
			height: 120px;
			left: 0;
			margin: 0 auto;
			position: absolute;
			right: 0;
			top: 50px;
			width: 120px;
			text-align: center; }
		.icon-plans {
			background-image: url(../images/icone-plan-vert.png); }
		.icon-inclus {
			background-image: url(../images/icone-inclus.png); }
		.icon-inclus-yellow {
			background-image: url(../images/icone-inclus-vert.png); }
		.icon-photos {
			background-image: url(../images/icone-photos-vert.png); }
		.icon-projet {
			background-image: url(../images/icone-projet.png); } } }
@media screen and (max-width: $sm-max) {
	.module-sections {
		background-image: none;
		padding: 40px 0;
		.item-box {
			height: 200px;
			margin: 20px auto 0;
			h3 {
				font-size: 30px; }
			.icon-plans, .icon-inclus, .icon-photos, .icon-projet {
				width: 80px;
				height: 80px;
				top: 30px; } } } }


/* Partenaires - Index */
.module-partenaires {
	background-color: #d8d8d8;
	padding: 85px 0;
	@media screen and (max-width: $sm-max) {
		img {
			margin: 15px auto; } } }


/* Projet CDD - Index */
.module-projet-cdd {
	background-color: #fff;
	padding: 100px 0;
	h3 {
		color: #0c1a1c;
		font-size: 36px;
		font-weight: 400;
		margin: 0 auto;
		padding-top: 35px;
		text-align: center; }
	@media screen and (max-width: $sm-max) {
		padding: 70px 0;
		h3 {
			padding-top: 0; }
		img {
			margin: 25px auto; } } }

/*****************************************************/

//FOOTER

.module-footer {
	position: relative;
	.copyright {
		background: #000708;
		color: #909090;
		font-family: $font-3;
		font-size: 12px;
		font-weight: 300;
		line-height: 24px;
		padding: 28px 15px;
		text-align: center;
		img {
			top: -4px;
			display: inline;
			position: relative; } } }
